import {
  CreateProject,
  createProject,
  updateProject,
} from '@/data/services/projectServices';
import { projectsQuerykeys } from '@/data/services/querykeys';
import { eventTrack } from '@/functions/eventTrack';
import { EventTrack } from '@/models/EventTrack';
import { Project } from '@/models/Project';
import alert from '@/utils/UseAlert';
import { buildChangedObject } from '@/utils/buildChangedObject';
import { getErrorMessage } from '@/utils/getErrorMessage';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useHackathon from '../hackathon/HackathonGeneral.hooks';
import ProjectForm, { ProjectFields } from './ProjectForm';
import {
  adapterProjectToCreateProject,
  generateFlagsPayload,
  generateImagePayload,
} from '@/utils/project';

type ProjectServiceProps = {
  project?: Project;
  onSuccess?: (project: Project) => void;
};

export default function ProjectService({
  project: projectDefault,
  onSuccess,
}: ProjectServiceProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'projects.fields',
  });
  const [tForm] = useTranslation('translation', {
    keyPrefix: 'projects.form',
  });

  const [project, setProject] = useState(projectDefault);
  const [hasChanges, setHasChanges] = useState(false);

  const queryClient = useQueryClient();

  const { hackathon } = useHackathon();

  const invalidate = async () =>
    queryClient.invalidateQueries(projectsQuerykeys.list._def);

  const handleUpdate = async ({
    isHackathon,
    courseFlag,
    image,
    imageFile,
    ...projectUpdate
  }: Partial<ProjectFields>) => {
    if (!project) throw new Error('Project not created');
    const imagePayload = generateImagePayload({ image, imageFile });
    const flagsPayload = generateFlagsPayload(
      { isHackathon, courseFlag },
      hackathon,
      projectUpdate.flags,
    );
    const fieldsProject: Partial<CreateProject> = {
      ...adapterProjectToCreateProject(projectUpdate),
      ...imagePayload,
      ...flagsPayload,
    };
    const originalProject = adapterProjectToCreateProject(project);
    const projectChanges = buildChangedObject(originalProject, fieldsProject);
    const updatedProject = await updateProject(project.id, projectChanges);
    alert.success(tForm('successUpdatedMessage'));
    return updatedProject;
  };

  const handleCreate = async ({
    isHackathon,
    courseFlag,
    image,
    imageFile,
    ...projectCreate
  }: ProjectFields) => {
    const imagePayload = generateImagePayload({ image, imageFile });
    const flagsPayload = generateFlagsPayload(
      { isHackathon, courseFlag },
      hackathon,
    );
    const projectPayload = {
      ...adapterProjectToCreateProject(projectCreate),
      ...imagePayload,
      ...flagsPayload,
      shared: true,
    } as CreateProject;

    const newProject = await createProject(projectPayload);
    const event: EventTrack = {
      category: newProject.tool.image ?? '',
      action: 'Add Project',
      label: String(project?.teacherMentor) ?? 'N/A',
    };
    eventTrack(event);
    alert.success(tForm('successAddedMessage'));
    return newProject;
  };

  const handleSubmit = (projectFields: ProjectFields) => {
    const response = !project
      ? handleCreate(projectFields)
      : handleUpdate(projectFields);

    return response;
  };
  const { mutate: onSubmit, isLoading } = useMutation(handleSubmit, {
    onError: err => alert.error(getErrorMessage(err)),
    onSuccess: async project => {
      await invalidate();
      setProject(project);
      onSuccess?.(project);
      setHasChanges(false);
    },
  });

  return (
    <ProjectForm
      project={project}
      onSubmit={onSubmit}
      isLoading={isLoading}
      hasChanges={hasChanges}
      setHasChanges={setHasChanges}
      saveButtonText={!project ? t('publish') : t('update')}
    />
  );
}

import IconButton from '@/components/common/buttons/IconButton';
import { deleteProject, ProjectFilters } from '@/data/services/projectServices';
import { projectsQuerykeys } from '@/data/services/querykeys';
import { Project } from '@/models/Project';
import alert from '@/utils/UseAlert';
import { getErrorMessage } from '@/utils/getErrorMessage';
import { ROUTES } from '@/utils/routes';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type HackathonVotingProps = {
  project: Project;
  filters?: ProjectFilters;
};

export default function OwnerActions({ project }: HackathonVotingProps) {
  const queryClient = useQueryClient();

  const { t } = useTranslation('translation', {
    keyPrefix: 'projects.card.actions',
  });

  const { mutate: removeProject, isLoading: isDeleting } = useMutation(
    deleteProject,
    {
      onSuccess: () => queryClient.invalidateQueries(projectsQuerykeys.list()),
      onError: e => alert.error(getErrorMessage(e)),
    },
  );

  const navigate = useNavigate();

  const onEdit: MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    e.stopPropagation();
    navigate(ROUTES.PROJECTS.FORM.BASE, {
      state: project,
    });
  };

  const onDelete: MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    e.stopPropagation();
    removeProject(project.id);
  };

  return (
    <div className="flex gap-2">
      <IconButton
        icon={<PencilIcon />}
        tooltip={t('edit')}
        className="w-5 text-primary"
        onClick={onEdit}
        testId="editProject"
      />
      <IconButton
        icon={<TrashIcon />}
        onClick={onDelete}
        loading={isDeleting}
        className="w-5 text-error"
        tooltip={t('delete')}
        testId="deleteProject"
      />
    </div>
  );
}
